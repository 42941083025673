.card-row:hover {
  background-color: #e8e8ee;
  transition: all .1s ease-out;
}
/* .fw-bold {
  font-weight: 900;
} */
.solved {
  color: blue
}

.closed{
  color: red
}

.open {
  color: rgb(227, 79, 50)
}
.new{
  color: rgb(255, 176, 87)
}

.pending{
  color:blueviolet
}
.hold {
  color:rgb(47, 57, 65);
}