.pagosm-cnt {
    background-color: #FFFFFF;
}

.pagosm-h {
    color: #111111;
    font-family: "HeadingProWide";
    font-weight: bold;
}


.steps-excel li {
    font-size: 12px;
    line-height: 15px;
    margin-bottom: 10px;
}


.thd {
    font-size: 15px;
}

.popover-c {
    position: absolute;
    z-index: 2;
    padding-left: 15px;
    margin-top: 5px;
    padding-right: 15px;
    padding-top: 10px;
    padding-bottom: 10px;
    display: block;
    max-width: 330px;
    font-family: var(--bs-font-sans-serif);
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    white-space: normal;
    line-break: auto;
    font-size: .875rem;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: padding-box;
    /* border: 1px solid #C4C4C4; */
    box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
}

.popover-title {
    color: #0066ff;
    font-weight: 700;
    font-size: 15px;
    line-height: 18px;
}

.popover-c:before {
    position: absolute;
    /* z-index: 3; */
    content: "";
    right: calc(80% - 10px);
    top: -10px;
    border-style: solid;
    border-width: 0 10px 10px 10px;
    border-color: transparent transparent #fff transparent;
    transition-duration: 0.3s;
    transition-property: transform;
}






.tbdy {
    border-top: 1px solid rgba(128, 128, 128, 0.466);
}

.card-pm {
    width: 17rem;
    margin-right: 2%;
    border-radius: 16px;
    background-color: #FFFFFF;
    box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
    padding: 1.5em 1.5em;
}

.container-input {
    display: flex;
    flex-wrap: wrap;
    padding-top: 20px;
    width: 100%;
    align-content: center;
    min-height: 45vh;
    padding-bottom: 70px;
}

.pm-table {
    border-collapse: collapse;
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: center;
}

.td-pm {
    padding: 0.9em 0.2em;
    font-size: 13px;
}

.cell-dbt {
    font-weight: 600;
    font-family: "HeadingProWide";
    font-size: 18px;
}

.cell-vt {
    color: #F26464;
}

.th-pm {
    padding: 0.8em 0.9em;
}

.d-ig {
    margin-left: auto;
    margin-right: auto;
    align-items: baseline;
}


.alert-np {
    color: #F26464;
    margin-left: 4px;
}

/*td th*/

.input-pm {
    border-top-left-radius: 40px;
    border-bottom-left-radius: 40px;
    border: 2px solid #111111;
    border-right: none !important;
    padding-left: 20px;
    text-align: left;
    background-color: transparent;
    max-width: 174px;
}



.formc-pm {
    width: 190px;
}

.input-pm::-webkit-outer-spin-button,
.input-pm::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
    margin: 0;
}

.input-pm[type="number"] {
    -moz-appearance: textfield;
}


.input-pm:hover,
.input-pm:focus {
    outline: none !important;
}

.input-group {
    width: 100% !important;
    flex-wrap: nowrap;
}


.input-group-text {
    background-color: transparent;
    border-top-right-radius: 40px;
    border-bottom-right-radius: 40px;
    border: 2px solid #111111;
    border-left: none;
    padding-left: 26px;
}

.btn-outline-primary,
.btn-pm {
    font-size: 14px;
    font-weight: 600;
    padding-left: 30px;
    padding-right: 30px;
}


.btn-pm:hover {
    background-color: transparent;
    border: 1px solid #0066ff;
    color: #0066ff;
}


.file-upload {
    font-weight: 700;
    padding-left: 22px;
    padding-right: 22px;
    padding-top: 3px;
    padding-bottom: 3px;
    color: #0066ff;
    border-radius: 40px;
    border: 1px solid #0066ff;
    margin-bottom: 0px;
    font-size: 13px;
}

.file-upload:hover {
    background-color: #0066ff;
    color: #FFFFFF;
}

.file-upload input {
    overflow: hidden;
    width: 0;
}



.filed-excel {
    display: inline-block;
    font-weight: 700;
    padding-left: 22px;
    padding-right: 22px;
    padding-top: 6px;
    text-decoration: none;
    padding-bottom: 6px;
    color: #343434 !important;
    border-radius: 40px;
    border: 1px solid #C4C4C4;
    margin-bottom: 0px;
    font-size: 13px;
}

.filed-excel:hover,
.filed-excel:active,
.filed-excel:focus {
    color: #FFFFFF !important;
    background-color: #343434;

}



.filed-excel,
.file-upload {
    cursor: pointer;
}




.form-control {
    border-radius: 40px;
    /* margin-bottom: 12px; */
    font-size: 14px;
}

.r-label {
    margin-bottom: 10px;
    font-size: 14px;
}

.disabled-pm {
    background-color: #0066ff !important;
    color: #FFFFFF !important;
    /* opacity: 0.5; */
    /* pointer-events: none; */
}

.btn-pm {
    background-color: #0066ff !important;
}


.btn-pm:active,
.btn-pm:hover,
.btn-pm:focus {
    background-color: #0066ff;
    color: #FFFFFF !important;
}


.input-pm.is-invalid,
.was-validated .input-pm:invalid {
    border-color: #F26464;

}

.input-pm.is-invalid::placeholder {
    color: #F26464;
}


.ig-pm.is-invalid,
.was-validated .ig-pm:invalid {
    border-color: #F26464;

}



@media (max-width: 1400px) {


    .table-responsive-xl {
        overflow-x: inherit;
        -webkit-overflow-scrolling: touch;
    }

    .popover-c {
        right: 0;
        top: 45px;
    }


    .pm-table {
        height: 100%;
        /* min-height: 515px; */
    }

    .popover-c:before {
        position: absolute;
        /* z-index: 3; */
        content: "";
        right: calc(45% - 10px);
        top: -10px;
        border-style: solid;
        border-width: 0 10px 10px 10px;
        border-color: transparent transparent #fff transparent;
        transition-duration: 0.3s;
        transition-property: transform;
    }



    .input-group-text {
        padding-left: 13px;
    }

    .card-pm {
        width: 29%;
        margin-right: 4%;
    }

    .formc-pm {
        width: 270px;
    }

    .input-group {
        flex-wrap: nowrap;
        font-size: 13px;
    }

    /* .file-upload,
    .filed-excel {
        padding-left: 46px;
        padding-right: 46px;
    } */

    /* .btn-pm {
        padding-left: 60px;
        padding-right: 60px;
    } */

    .pm-table {
        width: 100% !important;
        padding-left: 0%;
        margin-left: auto;
        margin-right: auto;
        border-radius: 40px;
    }

    .pm-table,
    .thd,
    .tbdy,
    .th-pm,
    .td-pm,
    .tbl-rw {
        display: block;
    }


    .d-ig .form-control {
        margin-bottom: 0px !important;
    }

    .thd .tbl-rw {
        position: absolute;
        top: -9999px;
        left: -9999px;
    }

    .tbl-rw {
        margin-bottom: 20px;
        word-wrap: break-word;
    }

    .td-pm {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }


    .pm-card {
        width: 100%;
        max-width: 270px;
    }

    .btn-pm,
    .file-upload {
        width: 100%;
    }


    .filed-excel {
        width: 100%;
    }

    .td-pm:before {
        position: initial;
        top: 6px;
        left: 6px;
        padding-right: 10px;
        white-space: nowrap;
        content: attr(data-column);
        font-weight: bolder;
        color: #111111;
    }

    .d-ig {
        margin-left: 0px;
        margin-right: 0px;
    }

    .cell-dbt {
        font-family: "Montserrat", sans-serif;
    }

    .td-pm {
        font-size: 16px;
    }

    .tbdy {
        border-top: 0px;
    }

    .d-tbl {
        background-color: #FFFFFF;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        padding: 0em 1em;
        border-radius: 16px;
    }

}




@media (max-width: 990px) {

    .card-pm {
        width: 45%;
    }

}



@media (max-width: 768px) {
    .popover-c {
        right: 0;
        top: 33px;
    }

    .steps-excel {
        padding-left: 1rem
    }


    .popover-title {
        color: #0066ff;
        font-weight: 700;
        font-size: 13px;
        line-height: 18px;
    }

    .td-pm {
        font-size: 11px;
    }

    .input-pm,
    .ig-pm {
        font-size: 11px;

    }

    .pm-card .form-control {
        font-size: 11px;
    }


    .file-upload {
        padding-left: 36px;
        padding-right: 36px;
        padding-top: 0px;
        padding-bottom: 0px;
        font-size: 11px;
    }


    .formc-pm {
        padding-top: 3px;
        padding-bottom: 3px;
    }

    .filed-excel {
        font-size: 11px;
        padding-top: 3px;
        padding-bottom: 3px;
        padding-left: 36px;
        padding-right: 36px;
    }

    .btn-pm {
        padding-left: 50px;
        padding-right: 50px;
        padding-top: 3px;
        padding-bottom: 3px;
        font-size: 11px;
    }

}



@media (max-width: 544px) {

    .popover-c {
        right: 41px;
        top: 66px;
    }

    .td-pm {
        flex-direction: column;
    }

    .td-pm:before {

        font-size: 12px;
    }

    .d-ig {
        /* margin-top: 10px; */
        margin-left: auto;
        margin-right: auto;
    }

    .d-tbl {
        background-color: #FFFFFF;
        box-shadow: rgb(99 99 99 / 20%) 0px 2px 8px 0px;
        padding: 0;
        border-radius: 16px;
    }

    .td-pm {
        font-size: 14px;
    }

    .file-upload,
    .filed-excel,
    .btn-pm,
    .formc-pm {
        margin-top: 10px;
    }

}



@media (max-width: 477px) {

    .card-pm {
        width: 100%;
    }


    .btn-primary:disabled i {
        display: none;
    }



    .btn-primary:disabled {
        padding-left: 0px !important;
        padding-right: 0px !important;

    }

}




@media (max-width: 340px) {
    .pm-card {
        width: 100%;
        max-width: 200px;
    }

    .formc-pm {
        width: 200px;
    }


}

@media (max-width: 300px) {
    .td-pm {
        font-size: 11px;
    }

    .file-upload,
    .filed-excel,
    .btn-pm,
    .formc-pm {
        font-size: 12px;
    }

}