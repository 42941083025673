.npwd {
    padding-top: 1.5rem;
}

.nvb-pwd {
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.regresar {
    font-size: 18px;
    line-height: 21.94px;
    color: #111111;
    text-decoration: none !important;
    background-color: transparent !important;
    border: none !important;
}

.regresar:active,
.regresar:focus,
.regresar:hover {
    color: #111111;
}


.p-left {
    background-color: #FFFFFF;
    display: flex;
}

.l-container {
    margin-top: auto;
    margin-bottom: auto;
}

.rec-pwd {
    font-family: "HeadingProWide" !important;
    font-size: 42px;
    line-height: 50.41px;
    margin-bottom: 3%;
    color: #111111;
}

.p-pwd {
    color: #111111;
    width: 70%;
    margin-bottom: 4%;
    font-size: 18px;
    line-height: 21.94px;
}

.i-container {
    width: 435px;
}

.input-group2 {
    width: 100% !important;
    flex-wrap: nowrap !important;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    margin-bottom: 4%;
}

.input-pwd {
    border: 2px solid #111111;
    width: 80%;
    text-align: left;
    background-color: #ffffff;
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
    border-right: none !important;
    padding-right: 5%;
    padding-left: 5%;
    font-size: 16px;
}

.input-pwd::-moz-placeholder {
    color: #111111;
    opacity: 1 !important;
}

.input-pwd::-webkit-input-placeholder {
    color: #111111;
    opacity: 1 !important;
}

.input-pwd:hover,
.input-pwd:focus {
    outline: none !important;
}

.input-group-text-pwd {
    display: flex;
    border-left: none !important;
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
    align-items: center;
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;
    padding-right: 0.75rem;
    padding-left: 2.7rem;
    border: 2px solid #111111;
    font-weight: 400;
    line-height: 1.5;
    color: #111111;
    text-align: center;
    white-space: nowrap;
    background-color: #ffffff;
}

.fa-stack {
    font-size: 0.7em;
}

.fa-circle,
.numb {
    vertical-align: middle;
}

.fa-circle {
    color: #111111;
}

.numb {
    font-size: 15px;
    color: #FFFFFF;
}

.btn-rec {
    width: 100%;
    font-size: 18px;
    line-height: 21.6px;
    color: #F9FAFF;
    text-align: center;
}

.btn-rec:hover {
    background-color: #0066FF;
    border: 1px solid #0066FF;
}



.bg-right {
    background-color: #FFFFFF;
}

.pwd-vid {
    border-radius: 40px;
    height: 550px;
}

.pwd-f {
    font-size: 14px;
    line-height: 17.07px;
}


.lg-error1 {
    color: #E5332A;
    font-size: 12px;
    margin-bottom: 10px;
    text-align: left;
    padding-left: 7%;
}



.input-pwd.is-invalid,
.was-validated .input-pwd:invalid,
.input-group-text-pwd.is-invalid,
.was-validated .input-group-text-pwd:invalid {
    border-color: #E5332A;

}

.input-pwd.is-invalid::placeholder {
    color: #E5332A;
}

.input-group2.is-invalid,
.was-validated .input-group2:invalid {
    margin-bottom: 0px;
}


.btn-outline-dark {
    font-family: "HeadingProWide";
    font-size: 14px;
}


.btn-c,
.btn-ch {
    width: 100%;
}



@media (max-width: 991.98px) {

    .npwd {
        margin-bottom: 30%;
    }

    .ft-pwd {
        margin-top: 15%;
        margin-bottom: 35%;
    }

    .input-pwd {
        width: 100%;
    }

}


@media (max-width: 576px) {

    .ct {
        padding-left: 3rem;
        padding-right: 3rem;
    }

    .i-container {
        width: auto;
    }

    .input-pwd {

        padding-left: 1rem;
    }

    .input-group-text-pwd {
        padding-left: 1.5rem;
    }

    .p-pwd {
        margin-bottom: 8%;
    }

    .input-group2 {
        margin-bottom: 7%;
    }

}


@media (max-width: 539px) {
    .p-pwd {
        width: 100%;
    }
}

@media (max-width: 503px) {

    .mhdr {
      
        padding-right: 7%;
       
    }
}




@media (max-width: 476px) {

    .input-group2 {
        flex-wrap: nowrap;
    }

    .l-2,
    .l-3 {
        width: 84%;
    }

    .rec-pwd {
        font-size: 34px;
        line-height: 40.81px;
    }

    .p-pwd {
        font-size: 18px;
        line-height: 21.94px;
    }

    .btn-rec {
        font-size: 16px;
    }


    .input-group-text-pwd {
        padding-left: 1rem;
    }


    .ft-pwd {
        margin-top: 20%;
        margin-bottom: 40%;
        text-align: left;
    }


}



@media (max-width: 454px) {


    .regresar {
        font-size: 14px;
    }

    .l-1,
    .l-2,
    .l-3 {
        width: 80%;
        padding-bottom: 3%;
    }

    .p-pwd {
        font-size: 15px;
        line-height: 21.94px;
    }


    .input-pwd {
        font-size: 14px;
    }

    .numb {
        font-size: 12px;
    }

    .btn-rec {
        font-size: 14px;
    }

}



@media (max-width: 375px) {
    .ct {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }

    .btn-outline-dark {
        font-size: 13px;
    }

    .mhdr {
     
        padding-top: 7%;
     
    }


}

@media (max-width: 343px) {
    .btn-outline-dark {
        font-size: 11px;
    }
}



@media (max-width: 322px) {
    .ct {
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .rec-pwd {
        font-size: 26px;
        line-height: 26px;
        margin-bottom: 10px;
    }

    .mhdr {
        padding-right: 8%;
    }

}



@media (max-width: 290px) {
    .p-pwd {
        font-size: 13px;
        line-height: 18px;
    }

    .input-pwd,
    .pwd-f {
        font-size: 12px;
    }

    .numb {
        font-size: 10px;
    }


    .btn-rec {
        font-size: 12px;
    }

    .pwd-f {
        width: 80%;
        text-align: justify;
    }


    .mhdr {
        padding-right: 10%;
        padding-top: 8%;
    }

}


@media  (max-width: 285px) {
  
.modal-text {
    font-size: 21px;
    line-height: 25px;
}  
}


@media  (max-width: 263px) {
  
    .modal-text {
        font-size: 18px;
        line-height: 20px;
    }  
    }