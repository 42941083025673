.cardd,
.card-chart,
.card-sf {
    border-radius: 16px;
    background-color: #FFFFFF;
    box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
    padding: 1.5em 1.5em;
}

.card-body {
    flex: 1 1 auto;
    padding: 1rem 0.8rem;
}


.logout-rsp {
    cursor: pointer;
}


.btn-dark,
.debt-qty {
    font-family: "HeadingProWide";
    color: #111111;
}

.btn-dark {
    font-size: 14px;
    color: #FFFFFF;
    background-color: #111111;
}



.btn-d:hover {
    background-color: #0066FF;
    border: 1px solid #0066FF;
}





.fa-arrow-circle-right {
    color: #0066FF;
    font-size: 17px;
}

.status {
    color: #0066FF;
}

.debt-title,
.traf {
    color: #646E75;
    font-weight: 600;
    font-size: 15px;
}

.debt-qty {
    font-size: 38px;
}

.link-txt {
    font-weight: 700;
    font-size: 13px;
    color: #111111;
}

.s-status,
.exp,
.status,
.exp-date {
    font-size: 12px;
}

.s-status,
.exp,
.exp-date {
    color: #111111;
}

.input-group {
    margin-bottom: 6px;
}


.b-input {
    margin-bottom: 10px;
    display: block;
    width: 100%;
    padding-top: .375rem;
    padding-bottom: .375rem;
    font-size: 14px;
    color: #111111;
    background-color: #FFFFFF;
    background-clip: padding-box;
    border: 1px solid #111111;
    font-family: "HeadingProWide" !important;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 40px;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    text-align: center;

}


.b-input::placeholder {
    color: #111111;
    opacity: 1;
}


.b-input::-moz-placeholder {
    color: #111111;
    opacity: 1;
}

.b-input:active,
.b-input:focus {
    outline: none !important;
}

.input-group-icon {
    display: flex;
    align-items: center;
    padding-right: 15px;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: center;
    white-space: nowrap;
    background-color: #ffffff;
    border: 1px solid #111111;
    border-top-right-radius: 40px;
    border-bottom-right-radius: 40px;
    margin-left: -1px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.chart {
    height: 220px;
    width: 100%;
}

.nav-link {
    background-color: transparent;
    color: #868686;
    padding-top: 0px;
    padding-bottom: 0px;
    font-size: 13px;
}

.nav-pills .nav-link:hover {
    color: #111111;
    outline: 1px solid #111111;
}


.nav-pills .nav-link.active {
    background-color: #111111;
    color: #FFFFFF;
    padding-top: 0px;
    padding-bottom: 0px;
}


.table1 {
    font-size: 12px;
}

.card-2 {
    border-radius: 16px;
    background-color: transparent;
    padding-top: 1.5em;
}


hr {
    margin-bottom: 20px;
    opacity: 1 !important;
    color: #111111 !important;
}


.cardsf-title,
.cardsa-title {
    font-family: "HeadingProWide" !important;
    color: #111111;
}


.modal-sa .modal-content{
    border-radius: 20px;
}

.modal-sa .modal-header {
    border-bottom: none;
}


.modal-sa .btn-close {
    background: transparent url('../../assets/svg/close-dark.svg') center auto no-repeat !important;
    opacity: 1;
}


.menu-link {
    color: #111111 !important;
}

.gb {
    font-family: "HeadingProWide" !important;
    color: #111111;
    font-size: 16px;
    word-break: break-all;
}

.s-internet,
.s-transporte {
    color: #646E75;
    font-size: 13px;
    font-weight: 400;
}

.icon-arrw {
    font-size: 22px;
}

.menu-icon {
    margin-right: 9px;
    cursor: pointer;
}

.a-capacidad {
    color: #111111;
    font-size: 15px;
}

.globe-icon {
    width: 25px;
    height: 25px;
}

.icon-trnsprt {
    font-size: 19px;
}

.icon-arrw {
    margin-right: 6px;
}


.table1 td {
    padding-bottom: 9px;
    padding-right: 7px;
}


/**/

.navbar-responsive {
    margin-bottom: 20px;
    padding-top: 20px;
    align-items: center;
}

.nb-container {
    padding: 0;
}


.navbar-responsive>.container-fluid {
    display: flex;
    flex-wrap: inherit;
    align-items: center;
    justify-content: flex-start;

}

.navbar-responsive .navbar-toggler-icon {
    background-image: url('../../assets/img/menu-icon.png');

}

.navbar-toggler {
    margin-right: 25px;
    padding: 0px;
}

/**/

.div-sa {
    align-items: center;
}

/**/

.navbar-responsive .nav-item {
    margin-bottom: 10px;
}


.navbar-responsive .nav-link {
    color: #111111;
    margin-bottom: 10px;
}


.navbar-responsive .nr-icon {
    color: #111111;
    font-size: 16px;
}


/* Modal */

.bdg-int {
    background-color: #DCDFEC;
    color: #111111;
    font-size: 20px;
    margin-right: 20px;
}

.divider {
    opacity: 0.1 !important;
}

/**/

.card-sf {
    height: 100%;
}

.dm-2 {
    background-color: transparent !important;
    border: none !important;
    padding-left: 5%;
}


.c-pwd {
    margin-bottom: 0px !important;
}






/**/


.swal2-container.swal2-center>.swal2-popup {
    grid-column: 2;
    grid-row: 2;
    align-self: center;
    justify-self: center;
    border-radius: 50px;
}


/**/

.r-errormsg {
    color: #E5332A;
    font-size: 12px;
    margin-top: 0px;
    text-align: left;
    padding-left: 12%;
    margin-bottom: 0px;
}



.crd-debt {
    margin-bottom: 0.5rem;
}



.b-input.is-invalid,
.was-validated .b-input:invalid {
    border-color: #E5332A;
    padding-top: .375rem;
    padding-bottom: .375rem;
}

.b-input.is-invalid::placeholder {
    color: #E5332A;
}


.link-pdf {
    color: #0066FF;
    text-decoration: none;
    font-weight: bold;
}
