.mhdr {
    border: none !important;
    padding-top: 6%;
    padding-right: 5%;
    padding-left: 5%;
    padding-bottom: 6%;
}


.modal-dialog {
    max-width: 600px;
    min-width: 400px;
    height: auto;
}


.close-modal {
    background: url("../../../assets/svg/closeicon.svg");
    background-size: cover;
    opacity: 1;
}

.modal1 {
    border-radius: 40px;
    box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
    border: none !important;
    width: auto;
}

.modal-icon {
    font-size: 60px;
}

.modal-icon-success {
    color: #0066FF !important;
}


.modal-icon-error {
    color: #E5332A;
}



.modal-text {
    color: #111111;
    font-family: "HeadingProWide" !important;
    font-size: 32px;
    width: 100%;
    margin-top: 2%;
    line-height: 38px;
    margin-left: auto;
    margin-right: auto;

}

.mbdy {
    padding-right: 30px;
    padding-left: 30px;
    padding-bottom: 11%;
}

.m-button-wrapper {
    width: 79%;
    margin-left: auto;
    margin-right: auto;
}


@media (max-width: 426px) {

    .modal-dialog {
        min-width: 50%;
        height: auto;
    }


    .mbdy {
        padding-right: 20px;
        padding-left: 20px;
    }


    .modal-text {
        font-size: 28px;
        line-height: 30px;
    }


}



@media (max-width: 363px) {

   

    .modal-text {
        font-size: 24px;
        line-height: 28px;
    }

}