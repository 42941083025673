.bg-left {
    background: linear-gradient(rgba(0, 0, 0, 0.40),
            rgba(0, 0, 0, 0.40)), url('../../assets/img/bg-left.jpg');
    height: 100vh;
    border-color: rgba(0, 0, 0, 1);
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
}

.left-text {
    font-family: "HeadingProWide" !important;
    color: #FFFFFF;
    font-size: 50px;
    text-align: left;
    line-height: 50px;
}

.l-txt {
    font-size: 45px;
    text-align: left;
    color: #FFFFFF;
    font-weight: 200;
    line-height: 43px;
}

.logo-dark {
    width: 100%;
    margin-bottom: 8%;
}

.lg-error {
    color: #E5332A;
    font-size: 12px;
    margin-bottom: 0px;
    text-align: left;
    padding-left: 7%;
}

.login-dv {
    width: 60%;
    height: auto;
    margin: 10px auto;
}

.email-input,
.pwd-input {
    border-radius: 40px;
    border: 2px solid #111111;
    background-color: #FFFFFF !important;
    display: block;
    width: 100%;
    color: #8f2f2f;
    padding: 0.375rem 1.75rem;
    text-align: left;
}


.email-input::-moz-placeholder {
    color: #111111;
    opacity: 1;
}

.email-input::-webkit-input-placeholder {
    color: #111111;
    opacity: 1;
}

.pwd-input::-moz-placeholder {
    color: #111111;
    opacity: 1;
}

.pwd-input::-webkit-input-placeholder {
    color: #111111;
    opacity: 1;
}


.email-input {
    margin-bottom: 3%;
}

.btn-login {
    margin-top: 4.5%;
    box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
    box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
    margin-bottom: 0px;
    letter-spacing: 2px;
    background-color: #111111;
    line-height: 21.6px;
}

.btn-login:hover {
    background-color: #0066FF;
    border: 1px solid #0066FF;
}


.o-pwd-d {
    margin-top: 8%;
}

.o-pwd {
    color: #111111;
}


.o-pwd:active,
.o-pwd:focus,
.o-pwd:hover {
    color: #111111;
}

.website,
.sm {
    color: #111111;
    font-weight: 600;
}

.website {
    text-decoration: none;
}

.website:hover, .website:active, .website:focus {
    color: #0066FF !important;
}

.icon-sm {
    color: #111111;
    font-size: 22px;
}


.login {
    background-color: #FFFFFF;
}

.footer-a {
    padding-top: 3rem !important;
}

.cont-lg {
    height: 80%;
    padding-top: 20%;
}


.hidden-div {
    display: none;
}


@media (max-width: 992px) {
    .login {
        height: 100vh;
    }
}


@media (max-width: 768px) {
    .cont-lg {
        height: auto;
        padding-top: 20%;
    }
}


@media (max-width: 638px) {
    .cont-lg {
        padding-top: 35%;
    }
}

@media (max-width: 452px) {
    .cont-lg {
        padding-top: 45%;
    }

    .footer-a {
        font-size: 12px;
    }

    .o-pwd {
        font-size: 14px;
    }

    .email-input,
    .pwd-input {
        font-size: 14px;
    }
}


@media (max-width: 402px) {
    .login-dv {
        width: 70%;

    }

    .cont-lg {
        padding-top: 58%;
    }
}

@media (max-width: 375px) {

    .cont-lg {
        padding-top: 54%;
    }
}


.email-input:active,
.email-input:focus {
    outline: none !important;
}

.pwd-input:active,
.pwd-input:focus {
    outline: none !important;
}



.email-input.is-invalid,
.was-validated .email-input:invalid {
    border-color: #E5332A;
    padding-top: .375rem;
    padding-bottom: .375rem;
}

.email-input.is-invalid::placeholder {
    color: #E5332A;
}


.pwd-input.is-invalid,
.was-validated .pwd-input:invalid {
    border-color: #E5332A;
    padding-top: .375rem;
    padding-bottom: .375rem;
}

.pwd-input.is-invalid::placeholder {
    color: #E5332A;
}