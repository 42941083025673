.main-cnt {
    background-color: #FFFFFF;
}



.main-ct {
    padding-top: 1.6rem !important;

}



#content {
    width: 100%;
    padding: 0;
    min-height: 100vh;
    -webkit-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}


.nb-container {
    display: none;
}


@media (max-width: 1400px) {

    .cardd,
    .card-2,
    .card-chart,
    .card-sf {
        height: 100%;
    }


    .cardsf-title,
    .cardsa-title {
        font-size: 17px;
    }

    .card,
    .card-2 {
        padding: 1em 0.5em;
    }

    .gb {
        font-size: 20px;
    }

    .div-sa {
        flex-direction: column;
        align-items: flex-start;
    }

    .div-gb {
        margin-bottom: 10px;

    }

    .table1 {
        font-size: 10px;
    }

    .b-input {
        font-size: 10px;
        padding-left: 8px;
        padding-right: 0px;
    }

    .btn-dark {
        font-size: 14px;
    }
}



@media (max-width: 1200px) {
    .debt-qty {
        font-size: 32px;
    }


    #content {
        padding-left: 0px !important;
    }

    .nb-container {
        display: block;
    }

    .main-nb {
        display: none;
    }

    .main-ct {
        margin-top: 0px !important;
    }

    .cardd,
    .card-chart,
    .card-sf,
    .card-2 {
        height: 100%;
    }

    .chart {
        width: 80%;
    }
}



@media (max-width: 992px) {
    .card {
        margin-bottom: 20px;
        height: auto;
    }

    .chart-dv {
        flex-direction: column;
    }

    .div-sa {
        flex-direction: row;
        align-items: center;
    }

    .table1 {
        font-size: 11px;
    }

    .div-gb {
        margin-bottom: 0px;
    }

    .b-input {
        padding-right: .75rem;
        padding-left: 6px;
        font-size: 13px;
    }

    .traf {
        margin-bottom: 10px !important;
    }
}


@media (max-width: 750px) {

    .info-wrp {
        padding-top: 30px;
    }


    .gb {
        word-break: break-all;
     font-size: 14.4px !important;
    }



}

@media (max-width: 430px) {
.div-sa {
    flex-direction: column;
}

.div-gb {

width: 100%;
    flex-direction: column;
}

.gb {
margin-bottom: 14px !important;
}

}


@media (max-width: 411px) {
    .navbar-brand {
        width: 120px;
    }

    .circle {
        height: 30px;
        width: 30px;
    }

    .nb-icon {
        font-size: 14px;
    }

    .span-ntf {
        padding: 4px;
    }

    .pfp {
        height: 30px;
        width: 30px;
    }

    .gb {
        font-size: 14px;
    }
}


@media (max-width: 375px) {
  


    .gb {
        font-size: 14px;
    }

    .b-input {
        padding-right: .75rem;
        padding-left: 6px;
        font-size: 10px;
    }

    .btn-dark {
        font-size: 13px;
    }


    .nav-pills {
        margin-top: 10px;
    }

    .nav-link {
        font-size: 11px;
        padding-left: 8px;
        padding-right: 8px;

    }

    .nav-pills .nav-link.active {
        background-color: #111111;
        color: #FFFFFF;
        padding-left: 8px;
        padding-right: 8px;
    }
}


@media (max-width: 343px) {
    .table1 {
        font-size: 10px;
    }

    .navbar-brand {
        display: none;
    }

    .debt-qty {
        font-size: 32px;
    }

    .link-txt,
    .exp,
    .exp-date,
    .status,
    .s-status,
    .a-capacidad {
        font-size: 11px;
    }


    .btn-dark,
    .btn-outline-dark {
        font-size: 11px;
    }


    .s-internet,
    .s-transporte {
        font-size: 11px;
    }

    .globe-icon {
        width: 20px;
        height: 20px;
    }

    .icon-trnsprt {
        font-size: 13px;
    }

    .icon-arrw {
        font-size: 14px;
    }

    .gb {
        font-size: 15px;
    }
}