.navbar-m {
    background-color: transparent;
}

.dropdown-pwd {
    min-width: 250px;
    width: 100%;
    /* max-width: 400px; */
    
}

.dropwdown-li-p {
    max-width: 370px;
    width: 100%;
    margin-left: auto;
}

.resumen {
    font-family: "HeadingProWide";
    color: #111111;
    font-size: 28px;
    margin-right: 10px;
}

.rif {
    margin-left: 10px;
    font-weight: 700;
    color: #111111;
    font-size: 13px;
}

.circle {
    border-radius: 50%;
    background-color: #FFFFFF;
    border: 1px solid #EFEFEF;
    height: 40px;
    width: 40px;
    display: flex;
}


.dropdown-menu {
    background-color: transparent !important;
    border: none !important;

}

.btn-cc {
    font-family: "Montserrat", sans-serif;
    font-weight: bold;
}


.dropdown-menu .show:hover,
.dropdown-menu .show:active {
    background-color: transparent !important;
}

.d-i-user:hover {
    background-color: transparent !important;
}


.lg-icon {
    cursor: pointer;
}

.span-ntf {
    transform: translate(-93%, -0%) !important;
    padding: 5px;
}

.nb-icon {
    margin-right: auto;
    margin-left: auto;
    font-size: 18px;
    color: #111;
}

/* .pfp {
    z-index: 1;
} */

.user {
    background-color: #ffffff;
   
  border-radius: 20px;
}

.btn-light {
    outline: none;
    background-color: #FFFFFF !important;
    border: none;
    font-size: 15px;
    font-weight: 600;
    padding-top: 9px;
}

.btn-light:focus {
    outline: none !important;
    border: none !important;
    color: #111111;
}

.fa-lock {
    color: #111111 !important;
}
.codigo { 
    font-size: 14px;
    font-weight: 500;
}
/* .dropdown-item:hover {
    background-color: #FFFFFF !important;
} */